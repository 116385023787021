import Vue from "vue";
import App from "./App.vue";
import router from './router'
import store from "./store";
import Vant from 'vant';
import axios from '@/utils/request'
import 'vant/lib/index.css';
import '@/assets/css/common.css';
import vueQr from 'vue-qr';
import VueLocalStorage from 'vue-localstorage'
import EasyCamera from 'easy-vue-camera';
//import VConsole from 'vconsole';

Vue.use(Vant);
Vue.use(vueQr);
Vue.use(VueLocalStorage)
Vue.use(EasyCamera);

Vue.prototype.$axios = axios
Vue.config.productionTip = false;
//const vConsole = new VConsole();
new Vue({
  router,
  store,
  vueQr,
  render: (h) => h(App),
}).$mount("#app");