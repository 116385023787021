import request from '@/utils/request'
const baseURL = '/h5-server';
//const baseURL = '';

// 获取照片规格描述
export const getPhotoSpec = (params)=>{
    return request({
        url: baseURL + `/api/h5/photo/getPhotoSpec`,
        method: 'get',
        params
    })
}
// 获取默认工作时间
export const getWork = (params)=>{
    return request({
        url: baseURL + '/api/h5/workLimit/getDefault',
        method: 'get',
        params
    })
}
// 验证(手机)
export const trustSign = (data)=>{
    return request({
        url: baseURL + '/api/h5/verify/trustSign',
        method: 'post',
        data
    })
}
// 验证(手机)
export const mobileTrustSign = (data)=>{
    return request({
        url: baseURL + '/api/h5/verify/mobile/trustSign',
        method: 'post',
        data
    })
}
// 验证(机械)
export const machineTrustSign = (data)=>{
    return request({
        url: baseURL + '/api/h5/verify/machine/trustSign',
        method: 'post',
        data
    })
}
// 提交照片
export const submitPhotos = (data)=>{
    return request({
        url: baseURL + '/api/h5/customer/updateFileBySid',
        method: 'post',
        data
    })
}
// 获取拍摄码
export const getShootCode = (data)=>{
    return request({
        url: baseURL + '/api/h5/shootCode/getShootCodeBySid',
        method: 'post',
        data
    })
}
// 查找附近的自助机
export const getNearbyselfmachines = (params)=>{
    return request({
        url: baseURL + '/api/h5/selfEquip/near',
        method: 'get',
        params
    })
}
// 获取照片结果
export const getResult = (sid)=>{
    return request({
        url: baseURL + `/api/h5/customer/getResultBySid/${sid}`,
        method: 'get',
    })
}
// 获取用户信息
export const getUserDesc = (sid)=>{
    return request({
        url: baseURL + `/api/h5/customer/config/getTotalByBusId/${sid}`,
        method: 'get',
    })
}
// 获取用户信息
export const getJumpUrl = (data)=>{
    return request({
        url: baseURL + `/api/h5/customer/toJumpUrlBySid`,
        method: 'post',
        data
    })
}
// 获取用户信息
export const renewShootCode = (data)=>{
    return request({
        url: baseURL + `/api/h5/customer/config/renew/shootCode`,
        method: 'post',
        data
    })
}
// 中行新获取业务ID号
export const getBusinessId = (data)=>{
    return request({
        url: baseURL + `/api/h5/customer/config/check/user/token`,
        method: 'post',
        data
    })
}